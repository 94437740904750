@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("fonts.css");

body {
  margin: 0;
  font-family: "Nunito", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.select__input {
  box-shadow: none !important;
}

model-viewer {
  max-width: 640px;
  height: 360px;
}

@media (min-width: 640px) {
  model-viewer {
    width: 640px;
    height: 360px;
  }
}

@media (min-width: 1281px) {
  model-viewer {
    width: 800px;
    height: 600px;
  }
}

:root {
	--d: 1000ms;
	--angle: 90deg;
	--gradX: 100%;
	--gradY: 50%;
	--c1: #f472b6;
	--c2: #f87171;
  --c3: #facc15;
}

@property --angle {
  syntax: '<angle>';
  initial-value: 90deg;
  inherits: true;
}

@property --gradX {
  syntax: '<percentage>';
  initial-value: 50%;
  inherits: true;
}

@property --gradY {
  syntax: '<percentage>';
  initial-value: 0%;
  inherits: true;
}

@keyframes borderRotate {
  100% {
    --angle: 420deg;
  }
}
