.loader {
  position: relative;
  width: 42px;
  height: 42px;
  border-radius: 50%;
  background: linear-gradient(#f472b6, #f87171, #facc15);
  animation: animate 1.2s linear infinite;
}

@keyframes animate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loader span {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: linear-gradient(#f472b6, #f87171, #facc15);
}

.loader span:nth-child(1) {
  filter: blur(0.6px);
}

.loader span:nth-child(2) {
  filter: blur(1.2px);
}

.loader span:nth-child(3) {
  filter: blur(3px);
}

.loader span:nth-child(4) {
  filter: blur(6px);
}

.loader:after {
  content: "";
  position: absolute;
  top: 1.2px;
  left: 1.2px;
  right: 1.2px;
  bottom: 1.2px;
  background: #f1f1f1;
  border: solid white 1.2px;
  border-radius: 50%;
}
