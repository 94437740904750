@font-face {
  font-family: "Nunito";
  src: local("Nunito"), local("Nunito-ExtraBold"),
    url(/fonts/nunito/Nunito-ExtraBold.ttf) format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Nunito";
  src: local("Nunito"), local("Nunito-Black"),
    url(/fonts/nunito/Nunito-Black.ttf) format("truetype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Nunito";
  src: local("Nunito"), local("Nunito-BlackItalic"),
    url(/fonts/nunito/Nunito-BlackItalic.ttf) format("truetype");
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: "Nunito";
  src: local("Nunito"), local("Nunito-BoldItalic"),
    url(/fonts/nunito/Nunito-BoldItalic.ttf) format("truetype");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: "Nunito";
  src: local("Nunito"), local("Nunito-Bold"),
    url(/fonts/nunito/Nunito-Bold.ttf) format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Nunito";
  src: local("Nunito"), local("Nunito-ExtraBoldItalic"),
    url(/fonts/nunito/Nunito-ExtraBoldItalic.ttf) format("truetype");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: "Nunito";
  src: local("Nunito"), local("Nunito-Medium"),
    url(/fonts/nunito/Nunito-Medium.ttf) format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Nunito";
  src: local("Nunito"), local("Nunito-Regular"),
    url(/fonts/nunito/Nunito-Regular.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Nunito";
  src: local("Nunito"), local("Nunito-MediumItalic"),
    url(/fonts/nunito/Nunito-MediumItalic.ttf) format("truetype");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "Nunito";
  src: local("Nunito"), local("Nunito-ExtraLightItalic"),
    url(/fonts/nunito/Nunito-ExtraLightItalic.ttf) format("truetype");
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: "Nunito";
  src: local("Nunito"), local("Nunito-ExtraLight"),
    url(/fonts/nunito/Nunito-ExtraLight.ttf) format("truetype");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "Nunito";
  src: local("Nunito"), local("Nunito-SemiBoldItalic"),
    url(/fonts/nunito/Nunito-SemiBoldItalic.ttf) format("truetype");
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: "Nunito";
  src: local("Nunito"), local("Nunito-LightItalic"),
    url(/fonts/nunito/Nunito-LightItalic.ttf) format("truetype");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Nunito";
  src: local("Nunito"), local("Nunito-SemiBold"),
    url(/fonts/nunito/Nunito-SemiBold.ttf) format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Nunito";
  src: local("Nunito"), local("Nunito-Italic"),
    url(/fonts/nunito/Nunito-Italic.ttf) format("truetype");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Nunito";
  src: local("Nunito"), local("Nunito-Light"),
    url(/fonts/nunito/Nunito-Light.ttf) format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "AllAroundGothic";
  src: local("AllAroundGothic-Bold"), local("AllAroundGothic-Bold"),
    url(/fonts/AllAroundGothic/AllRoundGothic-Bold.ttf) format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "AllAroundGothic";
  src: local("AllAroundGothic-Bold"), local("AllAroundGothic-BoldOblique"),
    url(/fonts/AllAroundGothic/AllAroundGothic-BoldOblique.ttf) format("truetype");
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: "AllAroundGothic";
  src: local("AllAroundGothic-Demi"), local("AllAroundGothic-Demi"),
    url(/fonts/AllAroundGothic/AllRoundGothic-Demi.ttf) format("truetype");
  font-weight: 600;
  font-style: normal;
}


@font-face {
  font-family: "AllAroundGothic";
  src: local("AllAroundGothic-Demi"), local("AllAroundGothic-DemiOblique"),
    url(/fonts/AllAroundGothic/AllAroundGothic-DemiOblique.ttf) format("truetype");
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: "AllAroundGothic";
  src: local("AllAroundGothic-Medium"), local("AllAroundGothic-Medium"),
    url(/fonts/AllAroundGothic/AllRoundGothic-Medium.ttf) format("truetype");
  font-weight: 500;
  font-style: normal;
}


@font-face {
  font-family: "AllAroundGothic";
  src: local("AllAroundGothic-Medium"), local("AllAroundGothic-MediumOblique"),
    url(/fonts/AllAroundGothic/AllAroundGothic-MediumOblique.ttf) format("truetype");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "AllAroundGothic";
  src: local("AllAroundGothic-Book"), local("AllAroundGothic-Book"),
    url(/fonts/AllAroundGothic/AllRoundGothic-Book.ttf) format("truetype");
  font-weight: 400;
  font-style: normal;
}


@font-face {
  font-family: "AllAroundGothic";
  src: local("AllAroundGothic-Book"), local("AllAroundGothic-BookOblique"),
    url(/fonts/AllAroundGothic/AllAroundGothic-BookOblique.ttf) format("truetype");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "AllAroundGothic";
  src: local("AllAroundGothic-XLig"), local("AllAroundGothic-XLig"),
    url(/fonts/AllAroundGothic/AllRoundGothic-XLig.ttf) format("truetype");
  font-weight: 300;
  font-style: normal;
}


@font-face {
  font-family: "AllAroundGothic";
  src: local("AllAroundGothic-XLig"), local("AllAroundGothic-XLigOblique"),
    url(/fonts/AllAroundGothic/AllAroundGothic-XLigOblique.ttf) format("truetype");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "LemonMilkPro";
  src: local("Lemon MILK Pro FTR"), local("Lemon Milk Pro Medium"),
    url(/fonts/LemonMilkPro/Lemon-Milk-Pro-Medium.otf) format("opentype");
  font-weight: 500;
  font-style: normal;
}
